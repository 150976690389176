import Cookies from 'js-cookie'; // eslint-disable-line

window.cse = {};
export const CookieManager = () => {};
export const TRUTHY = true;
export const FALSY = false;
export const COOKIE_CHOICE_MADE = 'choiceMade';
export const COOKIE_RECORD_GAME_PROGRESS = 'recordGameProgress';
export const MEASURMENT_ID = 'G-5GXPRHH84E';
export const DOMAIN = window.location.hostname;

export const COOKIE_GLOBAL_SETTINGS = {
  expires: 365,
  path: '',
  domain: DOMAIN
};
export const COOKIE_MANAGER_COOKES = [
  COOKIE_CHOICE_MADE,
  COOKIE_RECORD_GAME_PROGRESS
];

export const GA_COOKIES = [
  '_gat',
  '_ga',
  '_gid',
  '_ga_PGP3VYY6RR',
  '_ga_5GXPRHH84E',
  '_ga_DWHE86E1TS'
];

window.cse.gAMeasurmentId = false;
window.cse.domain = DOMAIN;

CookieManager.launchGA = () => {  // eslint-disable-line 
  CookieManager.setMeasurmentId();
  /* eslint-disable */
  var script = document.createElement( 'script' );
  script.setAttribute( 'src', `https://www.googletagmanager.com/gtag/js?id=${MEASURMENT_ID}` );
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', MEASURMENT_ID);
  /* eslint-enable */
};

CookieManager.setUpCookies = (cookieNames = [COOKIE_CHOICE_MADE, COOKIE_RECORD_GAME_PROGRESS]) => {
  cookieNames.forEach((cookieName) => {
    if (typeof Cookies.get(cookieName) === 'undefined') {
      Cookies.set(cookieName, FALSY, COOKIE_GLOBAL_SETTINGS);
    }
  });
};

CookieManager.hasChoiceBeenMade = () => Cookies.get(COOKIE_CHOICE_MADE) === String(TRUTHY);

CookieManager.playWithSaveData = () => {
  Cookies.set(COOKIE_CHOICE_MADE, TRUTHY, COOKIE_GLOBAL_SETTINGS);
  Cookies.set(COOKIE_RECORD_GAME_PROGRESS, TRUTHY, COOKIE_GLOBAL_SETTINGS);
  CookieManager.setMeasurmentId();
};

CookieManager.removeGACookies = () => {
  // Object.entries(Cookies.get()).forEach(([key]) => {
  //   if (!exclude.includes(key)) {
  //     Cookies.remove(key);
  //     Cookies.remove(key, COOKIE_GLOBAL_SETTINGS);
  //   }
  // });

  GA_COOKIES.forEach((cookieName) => {
    Cookies.remove(cookieName);
    Cookies.remove(cookieName, {
      path: '/',
      domain: `${DOMAIN}`
    });
    Cookies.remove(cookieName, {
      path: '/',
      domain: `.${DOMAIN}`
    });
    Cookies.remove(cookieName, {
      path: '',
      domain: `.${DOMAIN}`
    });
    Cookies.remove(cookieName, COOKIE_GLOBAL_SETTINGS);
  });
};

CookieManager.setMeasurmentId = (id = MEASURMENT_ID) => {
  window.cse.gAMeasurmentId = id;
};

CookieManager.playWithOutSaveData = () => {
  CookieManager.removeGACookies();
  Cookies.set(COOKIE_CHOICE_MADE, TRUTHY, COOKIE_GLOBAL_SETTINGS);
  Cookies.set(COOKIE_RECORD_GAME_PROGRESS, FALSY, COOKIE_GLOBAL_SETTINGS);
  CookieManager.setMeasurmentId(false);
};

CookieManager.resetCookies = () => {
  CookieManager.setMeasurmentId(false);
  Cookies.set(COOKIE_CHOICE_MADE, FALSY, COOKIE_GLOBAL_SETTINGS);
  Cookies.set(COOKIE_RECORD_GAME_PROGRESS, FALSY, COOKIE_GLOBAL_SETTINGS);
};

CookieManager.playingWithSavedData = () => {
  const saved = Cookies.get(COOKIE_RECORD_GAME_PROGRESS) === String(TRUTHY);
  if (saved) {
    CookieManager.setMeasurmentId();
  }
  return saved;
};

CookieManager.getCookieValues = () => {
  const values = {};
  COOKIE_MANAGER_COOKES.forEach((key) => {
    console.log(`${key}: ${Cookies.get(key)}`);
    values[key] = Cookies.get(key);
  });
  return values;
};

window.cse.CookieManager = CookieManager;

export default CookieManager;
